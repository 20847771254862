@charset "utf-8";

// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
		
		"syntax-highlighting",
        "bootstrap",
        "base"
;

.container-narrow {
  margin: 0 auto;
  max-width: 900px;
}
.container-narrow > hr {
  margin: 30px 0;
}

.logo {
    font-family: 'Exo 2', sans-serif;
    letter-spacing: -.1rem;
}

.first {
    font-weight: lighter;
}

.second {
    font-weight: bold;
    margin-right: 5px; 
}


.ce-callout {
  padding: 14px 20px 14px 40px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 6px;
  border-radius: 3px;
  font-size: 90%;
  color: #808080;
}
.ce-callout:before {
  padding-top: -14px;
  padding-left: -36px;
  margin-top: -14px;
  margin-left: -36px;
  font-family: 'Glyphicons Halflings';
    position: absolute;
    z-index: -1;
}

.ce-callout h4 {
margin-top: 0;
margin-bottom: 5px;
}

.ce-callout p:last-child {
margin-bottom: 0;
}

.ce-callout code {
border-radius: 3px;
}

.ce-callout+.bs-callout {
margin-top: -5px;
}

.ce-info {
    @extend .ce-callout;
	border-left-color: $brand-info;
}
.ce-info:before {
	content: "\e086"; 
	color: $brand-info;
}
.ce-warning {
    @extend .ce-callout;
	border-left-color: $brand-warning;
}
.ce-warning:before {
	content: "\e107"; 
	color: $brand-warning;
}
.ce-danger {
    @extend .ce-callout;
	border-left-color: $brand-danger;
}
.ce-danger:before {
	content: "\e209"; 
	color: $brand-danger;
}
.btn {
	border-radius: 5px;
}
#footer {
  margin-top: 180px;
  min-height: 120px;
  background-color: #232323;
  color: #eeeeee;
  margin-bottom: 0px;
  padding-top: 10px;
}

